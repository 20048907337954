@import url('https://fonts.googleapis.com/css2?family=Gruppo&display=swap');
body{
    background-color: rgba(0, 0, 0, 0.734);
    background-size: cover;
    font-family: 'Gruppo';
}
hr{
  width: 100%;
}
#mainMenu{
  position: absolute;
  float: left;
  transition: width 0.1s ease-out;
  background-color: rgb(50, 50, 250);
  border-radius: 5px;
  height: 100vh;
  z-index: 2;
  ul{
    padding: 0;
    margin: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    li{
      position: absolute;
      list-style: none;
      padding: 1rem;
      top: 100%;
      width: 100%;
      transition: top 0s 0.3s linear;
      color: white;
      font-size: 1.5rem;
      .rated, .nowplaying, .dmca--navbar{
        color: white;
        text-decoration: none;
      }  
      .languages-_container{
        display: flex;
        flex-direction: row;
        background-color: white;
        border-radius: 10px;
        width: 13.5rem;
        justify-content: space-around;
        align-items: center;
        height: 4rem;
        .spanish, .latin, .vose{
          height: 3rem;
          width: 3rem;
          background-size: 'cover';
          text-decoration: none;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .spanish{
          background-image: url('../public/flags/spainflag.png');
          height: 4rem;
          width: 4rem;
          margin-top: 1rem;
        }
        .latin{
          background-image: url('../public/flags/mexicoflag.png');
        }
        .vose{
          background-image: url('../public/flags/vose.png');
          margin-top: 1rem;
        }
      }
    }
    li:hover{
      color: #fff;
      text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px rgb(50, 50, 250),
      0 0 82px rgb(50, 50, 250),
      0 0 92px rgb(50, 50, 250),
      0 0 102px rgb(50, 50, 250),
      0 0 151px rgb(50, 50, 250);  
      animation: flicker 1s infinite alternate;
    }
    .dmca--navbar--container{
      .dmca--navbar:hover{
          color: #fff;
          text-shadow:
          0 0 7px #fff,
          0 0 10px #fff,
          0 0 21px #fff,
          0 0 42px rgb(50, 50, 250),
          0 0 82px rgb(50, 50, 250),
          0 0 92px rgb(50, 50, 250),
          0 0 102px rgb(50, 50, 250),
          0 0 151px rgb(50, 50, 250);  
          animation: flicker 1s infinite alternate;
      }
    }
  }
  .genre--button{
    height: 3rem;
    margin-top: 2rem;
  }
  .genre--section{
    padding: 1rem;
    margin-top: 6rem;
    .genre--container{
      display: none;
      flex-direction: column;
      margin-top: 11rem;
      padding: 1rem;
      font-size: 1.5rem;
      background-color: white;
      border-radius: 10px;
      .genre__options{
        text-decoration: none;
        margin-bottom: 1rem;
        color: rgb(50, 50, 250);;
      }
    }
  }
  .dmca--navbar--container{
    padding: 1rem;   
    height : 3vh;
    position : relative;
    top : 85vh;    
    .dmca--navbar{
      font-size: 1.5rem;
      color: white;
      text-decoration: none; 
    }
  }
  
  &.open{
    width: 250px;
    transition: width 0.1s ease-out;
    ul {
      top: 0;
      opacity: 1;
    }
    li {
      &:nth-child(1){
        top: 0px;
      }
      &:nth-child(2){
        top: 4rem;
      }
      &:nth-child(3){
        top: 8rem;
      }
      &:nth-child(4){
        top: 12rem;
      }
    }
  }
}
.map--container{
  display: flex;
  justify-content: center;
  padding: 5rem;
  .map{
    height: 61rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    .card--container{
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      align-items: center;
      color: white;
      margin-left:2rem; 
      margin-right: 2rem;
      .card{
        display: flex;
        flex-direction: column;
        height: 22rem;
        .linked-_card{
          text-decoration: none;
          .movie__picture{
            height: 18rem;
            width: 12rem;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: contain;  
            .vote-_container{
              padding: .5rem;
              .vote__average{
                height: 2rem;
                width: 2rem;
                background-color: white;
                border-radius: 5px;
                font-size: 1rem;
                padding: .5rem;
              }
            }
            .languages-_container{
              display: flex;
              flex-direction: row;
              margin-top: 16rem;
              width: 12rem;
              height: 2rem;
              justify-content: space-around;
              align-items: center;
              background-color: white;
              border-radius: 10px;
              .lat{
                height: 1.45rem;
                width: 1.45rem;
                background-size: contain;
                background-repeat: no-repeat;
              }
              .sub{
                height: 2rem;
                width: 2rem;
                background-size: contain;
                background-repeat: no-repeat;
                margin-top: .8rem;
              }
              .esp{
                height: 2rem;
                width: 2rem;
                background-size: contain;
                background-repeat: no-repeat;
                margin-top: .4rem;
              }
            }
          }
          .movie__picture:hover > .languages-_container{
            color: #fff;
            text-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px rgb(50, 50, 250),
            0 0 82px rgb(50, 50, 250),
            0 0 92px rgb(50, 50, 250),
            0 0 102px rgb(50, 50, 250),
            0 0 151px rgb(50, 50, 250);  
            animation: flicker 1s infinite alternate;
            background-color: rgb(50, 50, 250);          }
        }
      }
    }
    .no--results--container{
      display: flex;
      padding: 1rem;
      background-color: white;
      border-radius: 10px;
      height: 2rem;
      width: 100%;
      .no--results{
        font-size: 2rem;
      }
    }
  }
}
.map--relation--section{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  .map--relation__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1.5rem;
    width: 90%;
    .title--component--container{
      justify-content: center;
    }
    .map{
      border-radius: 10px;
      height: 25rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      .card--container{
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        align-items: center;
        color: white;
        .card{
          display: flex;
          flex-direction: column;
          height: 20rem;
          .linked-_card{
            text-decoration: none;
            .movie__picture{
              height: 16rem;
              width: 10rem;
              border-radius: 10px;
              background-repeat: no-repeat;
              background-size: contain;  
              .vote-_container{
                padding: .5rem;
                .vote__average{
                  height: 2rem;
                  width: 2rem;
                  background-color: white;
                  border-radius: 5px;
                  font-size: 1rem;
                  padding: .5rem;
                }
              }
              .languages-_container{
                display: flex;
                flex-direction: row;
                margin-top: 13rem;
                width: 9.7rem;
                height: 2rem;
                border: 2px solid rgb(50, 50, 250); 
                justify-content: space-around;
                align-items: center;
                background-color: white;
                border-radius: 10px;
                .lat{
                  height: 1.45rem;
                  width: 1.45rem;
                  background-size: contain;
                  background-repeat: no-repeat;
                }
                .sub{
                  height: 2rem;
                  width: 2rem;
                  background-size: contain;
                  background-repeat: no-repeat;
                  margin-top: .8rem;
                  border-radius: 3px;
                }
                .esp{
                  height: 2rem;
                  width: 2rem;
                  background-size: contain;
                  background-repeat: no-repeat;
                  margin-top: .4rem;
                }
              }
            }
            .movie__picture:hover > .languages-_container{
              background-color: rgb(50, 50, 250);            
            }
          }
        }
      }
      
      .no--results--container{
        display: flex;
        padding: 1rem;
        background-color: white;
        border-radius: 10px;
        height: 2rem;
        width: 100%;
        .no--results{
          font-size: 2rem;
        }
      }
    }
  }
}
.navbar{
  background-color: rgb(50, 50, 250);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: -1rem;
  .navbar__options{
      display: flex;
      flex-direction: row;
      list-style-type: none;
      font-size: 1rem;
      align-items: center;
      justify-content: space-between;
      .hamburger{
        display: inline-block;
        cursor: pointer;
        .bar1, .bar2, .bar3 {
          width: 35px;
          height: 5px;
          background-color: white;
          margin: 6px 0;
          transition: 0.4s;
          border-radius: 10px;
        }
      }
      .header--link{
        text-decoration: none;
        .web--name{
          display: flex;
          flex-direction: row;
          align-items: right;
          background-color: rgb(50, 50, 250);
          width: 100%;
          margin-right: 2rem;
          .logo{
            height: 2.5rem;
            width: 2.5rem;
            background-image: url('./images/logo.png');
            background-size: cover;
            margin-left: .5rem;
            display: flex;
            margin-top: 1rem;
          }
          .title{
            color: #fff;
            display: flex;
            flex-direction: row;
          }
          .title:hover{
            color: #fff;
            text-shadow:
            0 0 7px #fff,
            0 0 10px #fff,
            0 0 21px #fff,
            0 0 42px rgb(50, 50, 250),
            0 0 82px rgb(50, 50, 250),
            0 0 92px rgb(50, 50, 250),
            0 0 102px rgb(50, 50, 250),
            0 0 151px rgb(50, 50, 250);  
            animation: flicker 1s infinite alternate;
          }
        } 
      }
      .change .bar1{
        transform: translate(0, 11px) rotate(-45deg);
      }
      .change .bar2{
        opacity: 0;
      }
      .change .bar3{
        transform: translate(0, -11px) rotate(45deg);
      }
      .change--search {
        visibility: hidden;
      }     
      .search{
        font-size: 2rem;
      }
  }
}
.button--container{
  .button{
    font-size: 2rem;
    font-weight: bold;
    border: none;
    background: none;
    color: white;
  }
  .page__number{
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 2rem;
  }
  .button:active{
    color: rgb(50, 50, 250);
  }
}
.input--movie--container{
  display: none;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-evenly;
    .input__field{
      border-radius: 10px;
      width: 100%;
      height: 3rem;
      font-size: 2rem;
      border: none;
      margin-right: 1rem;
      font-family: 'Gruppo';
      display: flex;
      
    }
    .input__field:hover{
      border: 2px solid lightblue;
    }
    .search__button{
      border-radius: 10px;
      font-size: 2rem;
      background-color: rgb(50, 50, 250);
      font-family: 'Gruppo';
      color: white;
    }
    .search__button:hover{
      color: #fff;
      text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #0fa,
      0 0 82px #0fa,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;   
      animation: flicker 1s infinite alternate;
      font-size: 2rem;
    }
}
.modal--background {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: -79.5rem;
  background-color: rgba(10, 10, 10, 0.86);
  z-index: 1;
  .modal--card {
    margin: 0 auto;
    display: flex;
    margin-top: 20rem;
    width: 54rem;
    height: 47rem;
    background-color: lightgray;
    border-radius: 5px;
    overflow-y: auto;
    padding: 1rem;
    flex-wrap: wrap;
    justify-content: center;
      .modal__result{
        margin-bottom: 3rem;
        display: flex;
        justify-content: center;
        margin-right: 1rem;
        .modal__button{
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          text-decoration: none;
          height: 22rem;
          .movie__picture{
            height: 18rem;
            width: 12rem;
            margin-bottom: .5rem;
            border-radius: 10px;
            background-repeat: no-repeat;
            background-size: contain; 
            .vote-_container{
              padding: .5rem;
              .vote__average{
                height: 2rem;
                width: 2rem;
                background-color: white;
                border-radius: 5px;
                font-size: 1rem;
                padding: .5rem;
              }
            }
            
          }
          .languages-_container{
            display: flex;
            flex-direction: row;
            width: 12rem;
            height: 2rem;
            justify-content: space-around;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            .sub, .esp, .lat{
              height: 1rem;
              width: 2rem;
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
        }
        .modal__button:hover .title{
          background-color:rgb(50, 50, 250);
          animation: flicker 1s infinite alternate;
          color: white;
          border-radius: 10px;
        }
      }
      .no--results--container{
        display: flex;
        padding: 1rem;
        background-color: white;
        border-radius: 10px;
        height: 2rem;
        width: 100%;
        margin-top: 2rem;
        .no--results{
          font-size: 2rem;
        }
      }
  }
}
.feedback--form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .form--container{
      display: flex;
      flex-direction: column;
      align-items: center;           
      padding: 1rem;
      .sub--title{
          border-radius: 20px;
          display: flex;
          justify-content: center;
          width: 101.5%;
          text-align: center;
          margin-bottom: 2rem;
          color: black;
          h1{
            font-size: 2rem;
          }
      }
      .fields__container{
        display: flex;
        flex-direction: column;
        width: 250%;
        .name__field, .email__field, .text__field{
            height: 2rem;
            font-size: 1.5rem;
            border-radius: 10px;
            margin-bottom: 1rem;
            padding: 1rem;
            outline: none;  //to remove blue outline when it's focused
            color: whitesmoke;
        }
        input[type="text"], textarea {
            background-color : rgba(0, 0, 0, 0.734);
  
        }
        input[type="email"] {
            background-color : rgba(0, 0, 0, 0.734);
  
        }
        .text__field{
            height: 6rem;
        }
      }
      .submit__button{
          background-color: rgb(50, 50, 250);
          height: 2.5rem;
          width: 101.5%;
          font-size: 1.5rem;
          color: whitesmoke;
          margin-top: 3rem;
          border-radius: 20px;
      }
  }
  .contact--details{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      .social__media{
          display: flex;
          flex-direction: row;
          padding: 3rem;
      }
  }
}
.title--component--container{
  display: flex;
  padding: 1rem;
  justify-content: center;
  .title{
    background-color: white;
    border-radius: 10px;
    font-size: 3rem;
    padding: 1rem;
    font-weight: bolder;
  }
}
.dmca{
  display: flex;
  padding: 2rem;
  .dmca-_section{
    display: flex;
    background-color: white;
    padding: 2rem;
    font-size: 1.5rem;
    border-radius: 10px;
  }
}
.button{
  height: 4rem;
  color: red;
  margin-top: 30rem;
}
.dmca--feedback--form{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .form--container{
      display: flex;
      flex-direction: column;
      align-items: center;           
      padding: 1rem;
      .sub--title{
          border-radius: 20px;
          display: flex;
          justify-content: center;
          width: 101.5%;
          text-align: center;
          margin-bottom: 2rem;
          color: white;
          h1{
            font-size: 2rem;
          }
      }
      .fields__container{
        display: flex;
        flex-direction: column;
        width: 250%;
        .name__field, .email__field, .text__field{
            height: 2rem;
            font-size: 1.5rem;
            border-radius: 10px;
            margin-bottom: 1rem;
            padding: 1rem;
            outline: none;  //to remove blue outline when it's focused
            color: black;
        }
        input[type="text"], textarea {
            background-color : white;
  
        }
        input[type="email"] {
            background-color : white;
  
        }
        .text__field{
            height: 6rem;
        }
      }
      .submit__button{
          background-color: rgb(50, 50, 250);
          height: 3rem;
          width: 101.5%;
          font-size: 2rem;
          color: whitesmoke;
          margin-top: 3rem;
          border-radius: 20px;
      }
    }
}
.button--top{ // Hidden by default 
  visibility: hidden;
  position: fixed;
  z-index: 99;
  bottom: 2rem;
  right: 3rem;  
  outline: none;
  padding: 1rem;
  background-color: rgb(50, 50, 250);
  border-radius: 10px;
  color: white;
  font-size:2rem;
  width: 5rem;
  height: 3rem;
  cursor: pointer; // Add a mouse pointer on hover 
  opacity: 0.8;
  overflow:hidden;
  .text {
    width: 10rem;
    height: 5rem;
    position: absolute;
  }
  .primary {
    margin-left: 1rem;
    margin-top: -0.5rem;
    font-size: 3rem;
  }
  .secondary {
    top: 8rem;
    margin-left: 10%;
    margin-top: 17%;
  }
}
#top--button.show {
  visibility: visible;
  opacity: 0.5;
}
#top--button:hover{
  background-color: rgb(4, 195, 4);
}
#top--button:hover .primary {
  top:-80px
}
#top--button:hover .secondary {
  top:0;
}
.text {
  transition: top 0.7s;
  -moz-transition: top 0.7s; // Firefox
  -webkit-transition: top 0.7s; // Safari and Chrome 
  -o-transition: top 0.7s; // Opera 
  -ms-transition: top 0.7s; // Explorer
}
.movie--selected--section{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  z-index: 0;
  .movie--data--container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1.5rem;
    width: 90%;
    z-index: 0;
    h1{
      font-size: 3rem;
    }
    .movie__poster{
      border-radius: 10px;
      margin-bottom: 2rem;
    }
    .synopsis{
      padding: 1rem;
    }
    .director, .date, .duration{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    }
    .cast{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .rating{
      display: flex;
      flex-direction: column;
      align-items: center;
      .emoji__rating{
        margin-left: 1rem;
      }
    }
    .genre{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 3rem;
    }
    .trailer__container{
      display: flex;
      margin-bottom: 6rem;
      .frame{
        border-radius: 10px;
        width: 50rem;
        height: 30rem;
      }
      .trailer{
        margin-top: 2rem;
        border-radius: 10px;
      }
    }
    .trailer__not__available{
      margin-bottom: 2rem;
    }
    .trailer__availability{
      margin-top: 2rem;
      border-radius: 10px;
      color: white;
     }
     .download__button{
      text-decoration: none;
      background-color: green;
      border: none;
      border-radius: 10px;
      font-size: 2rem;
      padding: 1rem;
      margin-bottom: 6rem;
      color: white;
     }
     .download__button:hover{
        color: #fff;
        text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px rgb(50, 50, 250),
        0 0 82px rgb(50, 50, 250),
        0 0 92px rgb(50, 50, 250),
        0 0 102px rgb(50, 50, 250),
        0 0 151px rgb(50, 50, 250);  
        animation: flicker 1s infinite alternate;
        background-color: rgb(50, 50, 250);  
     }
     .languages-_container{
      display: flex;
      flex-direction: row;
      width: 14rem;
      height: 2rem;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 1rem;
      border: solid 1px black;
      border-radius: 10px;
      border: 2px solid rgb(50, 50, 250); 
      .lat{
        height: 1.5rem;
        width: 1.5rem;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .sub{
        height: 2rem;
        width: 2rem;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: .7rem;
      }
      .esp{
        height: 2rem;
        width: 2rem;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: .4rem;
      }
    }
  }
  &.open{
    width: 10rem  
  }
}
.contact--container{
  display: flex;
  margin-top: 2rem;
  justify-content: center;
  .contact{
    background-color: white;
    width: 90%;
    border-radius: 10px;
  }
}
footer{
  color: black;
  font-size: .5rem;
  background-image: radial-gradient(white, rgb(5, 5, 248));
  text-align: center;
  padding: 1rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2rem;
  .footer__link{
    text-decoration: none;
  }
  .go--container{
      color: #ED1D24;
  }
  .credit{
    margin-left: 1rem;
  }
}
.pulse {
  animation: pulse-animation 2s;
}
$animationSpeed: 30s;
// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 10))}
}
// Styling
.slider{
	background: white;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 6rem;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 80%;
	margin-bottom: 3rem;
  margin-top: 3rem;
  border-radius: 10px;
	&::before,
	&::after{
		content: "";
		height: 6rem;
		position: absolute;
		width: 200px;
		z-index: 2;
	}
	&::after{
		right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}
	&::before{
		left: 0;
		top: 0;
	}
	.slide-track{
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 20);
	}
}


@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(4, 195, 4);
    border-radius: 100px;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    border-radius: 100px;
  }
}
input[type="search"]::-webkit-search-cancel-button {  //styling clear button input field
  /* Remove default */
  -webkit-appearance: none;
  /*Your new styles */
  height: 3rem;
  width: 3rem;
  background-image: url('./images/x-icon.png');
  background-size: 'contain';
}
.neonText {
  animation: flicker 1.5s infinite alternate;
  color: #fff;
}
h1 {
  font-size: 2rem;    
}
.drop-in {
  animation: drop-in 1s ease 200ms backwards;
  
 }
@keyframes flicker{  //animation neon logo
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px rgb(50, 50, 250),
    0 0 82px rgb(50, 50, 250),
    0 0 92px rgb(50, 50, 250),
    0 0 102px rgb(50, 50, 250),
    0 0 151px rgb(50, 50, 250);
  }
  20%, 24%, 55% {        
      text-shadow: none;
  }    
}

@keyframes drop-in {
  from {
   opacity: 0;
   transform: translateY(-100px);
  }
  to {
   opacity: 1;
   transform: translate(0px);
  }
 }
 @keyframes square-in-hesitate {
  0% {
    clip-path: inset(100% 100% 100% 100%);
  }
  40% {
    clip-path: inset(33% 33% 33% 33%);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}
.square-in{
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) square-in-hesitate both;
}

@keyframes diamond-in-center {
  from {
    clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
  }
  to {
    clip-path: polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%);
  }
}
.diamond{
  animation: 4s cubic-bezier(.25, 1, .30, 1) diamond-in-center both;
}
@keyframes wipe-cinematic-in {
  from{
    clip-path: inset(100% 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
.wipe-in {
  animation: 1.5s cubic-bezier(.25, 1, .30, 1) wipe-cinematic-in both;
}
@media (max-width:1500px){
  .primary {
    margin-left: 15%;
    font-size: 5rem;
  }
}
@media(max-width:1100px){
  #mainMenu{
    .dmca--navbar--container{
      height : 3vh;
      position : relative;
      top : 85vh;    
    }
  }
}
@media(max-width: 850px){
  .movie--selected--section{
    .movie--data--container{
      .trailer__container{
        .frame{
          width: 28rem;
          height: 16rem;
        }
      }
    }
  }
  .dmca--feedback--form{
    .form--container{
        .fields__container{
          display: flex;
          width: 180%;
        }
      }
  }
  #mainMenu{
    .dmca--navbar--container{
      height : 3vh;
      position : relative;
      top : 83vh;    
    }
  }
  .feedback--form{
    .form--container{
      .fields__container{
        width: 150%;
      }
    }
  }
  .modal--background {
    .modal--card{
      .modal__result{
        margin-right: 4rem;
      }
      .no--results--container{
        display: flex;
        padding: 1rem;
        width: 75%;
        margin-right: 7rem;
      }
    }
  }
}
@media(max-width: 750px){
  .map--relation--section{
    .map--relation__container{
      .map{
        height: 40rem;
      }
    }
  }
}
@media(max-width: 500px){
  .map--relation--section{
    .map--relation__container{
      .map{
        height: 45rem;
      }
    }
  }
  .title--component--container{
    display: flex;
    padding: .5rem;
    justify-content: center;
    margin-bottom: 1rem;
    .title{
      font-size: 2rem;
    }
  }
  .dmca--feedback--form{
    .form--container{
        .fields__container{
          display: flex;
          width: 100%;
        }
      }
  }
  .header--link{
    .web--name{
      .title{
        margin-top: 1.5rem;
      }
      .logo{
        width: 1rem;
        height: 1rem;
        margin-bottom: 1rem;
      }
      .title{
        font-size: 1.5rem;
      }
    }
  }
  #mainMenu{
    z-index: 1;
    .dmca--navbar--container{
      height : 3vh;
      position : relative;
      top : 75vh;    
    }
  }
  .feedback--form{
    .form--container{
      width: 55%;
    }
  }
  .map--container{
    padding: 0rem;
    .map{
      justify-content: space-evenly;
      .card--container{
        margin-right: 0rem;
        margin-left: 0rem;
        .card{
          .linked-_card{
            .movie__picture{
              height: 19rem;
              width: 10rem;
              .languages-_container{
                width: 10rem;
                margin-top: 13rem;
              }
            }
          }
        }
      }
      .no--results--container{
        display: flex;
        width: 80%;
      }
    }
  }
  .movie--selected--section{
    .movie--data--container{
      .trailer__container{
        .frame{
          width: 22rem;
          height: 12rem;
        }
      }
      h1{
        font-size: 1.5rem;
      }
      .movie__poster{
        height: 20rem;
      }
    }
  }
  .button--top{ // Hidden by default 
    bottom: .5rem;
    right: .5rem;  
    width: 3rem;
    height: 2rem;
    .primary{     
      font-size: 6rem;
      left: .01rem;
      bottom: 1.8rem
    }
    .secondary{
      font-size: 2.5rem;
      left: .1rem;
    }
  }
  .input--movie--container{
    .search__button, .search__button:hover{
      font-size: 1rem;
    }
  }
 
  .modal--background{
    .modal--card{
      width: 28rem;
      height: 23rem;
      .modal__result{
        margin-right: 2rem;
        .modal__button{
          height: 18rem;
          width: 10rem;
          .movie__picture{
            width: 10rem;
          }
          .languages-_container{
            width: 10rem;
            margin-top: -3rem;
          }
        }
      }
      .no--results--container{
        display: flex;
        padding: 1rem;
        width: 70%;
        margin-right: 12rem;
      }
    }
  }
}
@media(max-width: 400px){
  .movie--selected--section{
    .movie--data--container{
      .trailer__container{
        .frame{
          width: 20rem;
          height: 12rem;
        }
      }
    }
  }
  #mainMenu{
    overflow: hidden;
    .genre--section{
      .genre--container{
        height: 20rem;
        overflow: scroll;
      }
    }
  }
  .modal--background {
    .modal--card{
      width: 25rem;
      .modal__result{
        .modal__button{
          .movie__picture{
            height: 16rem;
            width: 10rem;
          }
          .languages-_container{
            width: 10rem;
            margin-top: -1rem;
          }
        }
      }
      .no--results--container{
        width: 70%;
        margin-right: 4rem;
      }
    }
  }
  .map--relation--section{
    .map--relation__container{
      .map{
        height: 45rem;
        .card--container{
          .card{
            margin-right: 1rem;
            .linked-_card{
              .movie__picture{
                width: 9rem;
                .languages-_container{
                  width: 8.8rem;
                  margin-top: 12rem;
                }
              }
            }
          }

        }
      }
    }
  }
}
@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */ 
  /*        document.body.style.position = 'fixed';  //block scroll when menu's opened
        document.body.style.top = 0;
        document.body.style.bottom = 0;*/
  @media(max-width: 500px){
    #mainMenu{
      overflow: hidden;
    }
    .button--top{
      .primary {
        bottom: .3rem;
        font-size: 5rem;
      }
      .secondary{
        margin-top: 15%;
      }
    }
    .header--link{
      .web--name{
        .logo{
          width: 1rem;
          height: 1rem;
        }
        .title{
          font-size: 1.5rem;
        }
      }
    }
    .slider{
      border-radius: 5px;
    }
    
    .movie--selected--section{
      display: flex;
      justify-content: center;
      width: 90%;
      .movie--data--container{
        h1{
          font-size: 1.5rem;
        }
        .movie__poster{
          height: 20rem;
        }
       
        .trailer__container{
          .trailer{
            width: 20rem;
            height: 12rem;
          }
        }
      }
    }
    .input--movie--container{
      .search__button{
        font-size: 1rem;
      }
      .search__button:hover{
        font-size: 1rem;
      }
    }
    .modal--background{
      top: -10rem;
      .modal--card{
        width: 26rem;
        height: 23rem;
        .modal__result{
          .modal__button{
            height: 18rem;
            width: 10rem;
            margin-bottom: 1rem;
            .poster__movie__modal{
              height: 100%;
              width: 100%;
              background-size: contain;
              background-repeat: no-repeat;
            }
            .movie__title{
              width: 9rem;
            }
          }
        }
      }
    }
  }
  @media(max-width: 400px){
    #mainMenu{
      overflow: hidden;
    }
    .slider{
      border-radius: 5px;
    }
    .movie--selected--section{
      .movie--data--container{
        .trailer__container{
          .trailer{
            width: 18rem;
            height: 10rem;
          }
        }
      }
    }
    .modal--background{
      top: -10rem;
      .modal--card{
        width: 26rem;
        height: 23rem;
      }
    }
  }
}